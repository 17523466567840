"use strict";

const ComunitOnline = {
    initialized: false,
    components: {},
    autoInitComponents: true
};

ComunitOnline.components.postBroadcast = {
    initialized: false,
    selector: ".site-posts-table button",
    buttons: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
        this.initialized = true;
    },
    apply: (element) => {
        const component = ComunitOnline.components.postBroadcast;
        component.buttons.push(element);
        element.addEventListener("click", component.handler);
    },
    handler: (event) => {
        const button = event.target;
        const url = button.getAttribute("data-url");
        Biovision.jsonAjaxRequest("PUT", url, () => button.remove()).send();
    }
};

ComunitOnline.components.rawPostView = {
    initialized: false,
    selector: ".js-raw-post-view button",
    buttons: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
        this.initialized = true;
    },
    apply: (element) => {
        const component = ComunitOnline.components.rawPostView;
        component.buttons.push(element);
        element.addEventListener("click", component.handler);
    },
    handler: (event) => {
        const button = event.target;
        const source = button.closest("dd").querySelector("dd > .text");
        const container = button.parentNode.querySelector(".container");
        let destination = container.querySelector("textarea");
        if (!destination) {
            destination = document.createElement("textarea");
            destination.classList.add("auto-expand");
            destination.rows = 5;
            destination.setAttribute("data-min-rows", "5");
            destination.value = source.innerHTML;
            container.append(destination);
        }
    }
};

ComunitOnline.components.feed = {
    initialized: false,
    selector: ".js-feed-container",
    anchorSelector: ".js-feed-anchor",
    container: undefined,
    anchor: undefined,
    init: function () {
        this.container = document.querySelector(this.selector);
        this.anchor = document.querySelector(this.anchorSelector);
        if (this.container) {
            this.loadPosts();
            if (this.anchor) {
                window.addEventListener("scroll", this.scroll);
            }
        }
        this.initialized = true;
    },
    loadPosts: function () {
        const component = ComunitOnline.components.feed;
        const url = component.container.getAttribute("data-url");
        const request = Biovision.jsonAjaxRequest("get", url, function () {
            const response = JSON.parse(this.responseText);

            if (response.hasOwnProperty("data")) {
                component.parseResponse(response.data);
            }
            if (response.hasOwnProperty("links")) {
                const links = response.links;
                if (links.hasOwnProperty("next")) {
                    component.container.setAttribute("data-url", links.next);
                    component.anchor.classList.remove("loading");
                } else {
                    component.anchor.remove();
                    window.removeEventListener("scroll", component.scroll);
                }
            }
        });

        if (!component.anchor.classList.contains("loading")) {
            component.anchor.classList.add("loading");
            request.send();
        }
    },
    parseResponse: function (data) {
        const component = ComunitOnline.components.feed;
        data.forEach(function (post) {
            if (post.hasOwnProperty("meta")) {
                const li = document.createElement("li");
                li.innerHTML = post["meta"]["html_preview"];
                component.container.append(li);
            }
        });
    },
    scroll: function () {
        const component = ComunitOnline.components.feed;
        const box = component.anchor.getBoundingClientRect();
        const delta = window.innerHeight - box.top - component.anchor.offsetHeight;

        if (delta > 0) {
            component.loadPosts();
        }
    }
};

Biovision.components.comunitOnline = ComunitOnline;

window.ComunitOnline = ComunitOnline;